import styled, {css} from 'styled-components';

export const StyleWrapper = styled.div`
  width: 100%;
  max-width: 1070px;
  height: auto;
  margin-bottom: 30px;
  border-bottom: 1px solid ${({theme}) => theme.border};
  ${({ theme }) => theme.media.tablet} {
    width: 90%;
    max-width: 1070px;
  }
  ${({ theme }) => theme.media.desktop} {
    width: 90%;
    max-width: 1070px; 

  }
`;

export const Price = styled.h2`
  font-size: ${({theme}) => theme.font.size.xxm};
  color: rgba(246, 150, 0, 1);
  font-weight: 800;
  margin-top: 0;
  padding-bottom: 5px;
 
`
export const OnSell = styled.div`
  margin-top: 10px;
  font-size: ${({theme}) => theme.font.size.xs};
  color: rgba(0,160,240,1);
  border:1px solid rgba(0,160,240,1);
  padding : 5px;
  border-radius: 5px;
  font-weight: 600;
  text-align: center;
  letter-spacing: 0.8px;
`

export const StyledDiv = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 15px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-bottom: none;
  border-top: none;
  background-color: ${({ theme }) => theme.white};
  &:last-child {
    padding-bottom: 0;
 
  }
`;

export const Button = styled.div`
  background-color: ${({ theme }) => theme.fontlightblue};
    position: relative;
  border: none;
  border-radius: 5px;
  padding: 6px 8px;
  transition: 0.3s ease-in;
  ${({ theme }) => theme.media.desktop} {
    width: auto;
      max-width: 200px;
    background-color: ${({ theme }) => theme.fontlightblue};
    border: none;
    border-radius: 5px;
    padding: 8px 12px;
    transition: 0.3s ease-in;
    &:hover {
      background-color: ${({ theme }) => theme.blue};
      text-decoration: none;
    }
  }
`;

export const StyledA = styled.a`
  color: ${({ theme }) => theme.white};
  font-weight: 400;
  font-size: ${({ theme }) => theme.font.size.xxs};
  transition: 0.3s ease-in;
  &:hover {
    color: ${({ theme }) => theme.white};
    text-decoration: none;
  }
`;


export const StyledP = styled.div`
  font-size: ${({ theme }) => theme.font.size.xs};
  color: ${({ theme }) => theme.blackfont};
  padding: 5px 0 ;

  &:first-child {
    padding-top: 0;
  }
   &:last-child {
    padding-bottom: 5px;
  }
  p{
    padding-bottom: 2px;
  }
`;

export const StyledTitleH2= styled.h2`
font-size: ${({theme}) => theme.font.size.xxxm};
font-family: ${({theme}) => theme.font.family.Montserrat};
font-weight: 400;
color: ${({theme}) => theme.blackfont};
`
export const StyledLeftDescription = styled.div`
  margin-top: 10px;
  ${({ theme }) => theme.media.desktop} {
    margin-top: 0;
    margin-bottom: 15px;
  }`

export const StyledLeft = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  padding-bottom: 30px;

  ${({ theme }) => theme.media.phonetextbooks} {
    flex-direction: row;
  }
  ${({ theme }) => theme.media.tablet} {
    flex-direction: row;
  }
  ${({ theme }) => theme.media.desktop} {
    border-right: 1px solid rgba(0, 0, 0, 0.15);
    width: 67%;
    display: flex;  
    align-items: center;
  }
`;