import React from "react"
import SEO from 'components/SEO/seo'
import ElementarySchool14 from "components/Textbooks/ES14";
import MainTemplate from 'templates/MainTemplate'
import styled from "styled-components";


const StyledTextBoxes14 = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 20px;
  background-color: ${({theme}) => theme.body};
  font-family: ${({theme}) => theme.font.family.Montserrat};
  ${({theme}) => theme.media.desktop} {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 50px;
  }`

const StyleWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  height: auto;
  margin-bottom: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  ${({theme}) => theme.media.tablet} {
    width: 90%;
    max-width: 1070px;
  }
  ${({theme}) => theme.media.desktop} {   
    max-width: 1070px;
    height: auto;
    margin-bottom: 30px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }
  ${({theme}) => theme.media.large} {
    width: 80%;
  }
`;

const StyledTitile = styled.div`
  padding: 6px 18px;
  background-color: rgba(0, 0, 0, 0.1);
  font-weight: 400;
`

const StyledH2 = styled.h2`
  font-size: ${({theme}) => theme.font.size.xxm};
  font-family: ${({theme}) => theme.font.family.Montserrat};
  font-weight: 400;
  color: ${({theme}) => theme.blackfont};
`

const SPPs = () => (

  <MainTemplate>
    <SEO title="Podręczniki dla szkół podstawowych klasy 1-4"/>

    <StyledTextBoxes14>
      <StyleWrapper>
        <StyledTitile><StyledH2>Podręczniki dla szkół podstawowych klasy
          1-4</StyledH2></StyledTitile>
        <ElementarySchool14 isFirst category="1"/>
        <ElementarySchool14 isSecond isFirst category="2"/>
        <ElementarySchool14 isSecond isFirst category="3"/>
        <ElementarySchool14 noBorder isSecondLeftSide category="4" />
      </StyleWrapper>
    </StyledTextBoxes14>
  </MainTemplate>
)

export default SPPs