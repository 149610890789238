import styled, {css} from "styled-components";

export const StyledSP14 = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  background-color: ${({theme}) => theme.body};
  font-family: ${({theme}) => theme.font.family.Montserrat};

`;


export const StyledInner = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.7);
  flex: 1;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  ${({theme}) => theme.media.desktop} {
    height: auto;
    display: flex;
    flex-direction: row;
    background-color: rgba(255, 255, 255, 0.7);
    flex: 1;
    border-bottom: ${({ noBorder }) => noBorder ? 'none' : '1px solid rgba(0, 0, 0, 0.15)'};
  
  }
`;

export const StyleUp = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 15px;
`;

export const StyledLeft = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: auto;
  padding-bottom: 30px;
  position: relative;
  ${({theme}) => theme.media.phonetextbooks} {
    flex-direction: column;
    align-items: center;
  }
  ${({theme}) => theme.media.tablet} {
    flex-direction: column;
  }
  ${({theme}) => theme.media.desktop} {
    width: ${({isOneLine}) => (isOneLine ? "65%" : "98%")};
    flex-direction: row;
    display: flex;
    justify-content: ${({isOneLine}) =>
  isOneLine ? "flex-start" : "flex-start"};
    height: auto;
    padding-bottom: 30px;
    border-right: 1px solid rgba(0, 0, 0, 0.15);
    ${({isThird}) => isThird && css`
              display: grid;
              grid-template-columns: 0.7fr 1.3fr;
            `}
  }
`;

export const StyledLeftWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: auto;
  padding-bottom: 30px;
  position: relative;
  ${({theme}) => theme.media.phonetextbooks} {
    flex-direction: column;
    align-items: center;
  }
  ${({theme}) => theme.media.tablet} {
    flex-direction: column;
  }
  ${({theme}) => theme.media.desktop} {
    width: ${({isSecondLeftSide}) => (isSecondLeftSide ? "70%" : "100%")};
    flex-direction: column;
    display: flex;
    justify-content: ${({isOneLine}) =>
  isOneLine ? "flex-start" : "flex-start"};
    height: auto;
    padding-bottom: 30px;   
    ${({isThird}) => isThird && css`
              display: grid;
              grid-template-columns: 0.7fr 1.3fr;
            `}
  }
`;

export const StyledRight = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
  position: relative;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  ${({theme}) => theme.media.tablet} {
    border-top: none;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 2%;
    flex-direction: row;
  }
  ${({theme}) => theme.media.desktop} {
    width: auto;
    display: flex;
    justify-content: center;
    padding-top: 0;
    align-items: center;
    padding-left: 2%;
    flex-direction: ${({isOneLine}) => (isOneLine ? "column" : "column")};
  }
`;

export const StyledRightInner = styled.div`
  width: 90%;
    position: relative;
  display: flex;
  flex-direction: ${({isOneLine}) => (isOneLine ? "row" : "row")};
  ${({theme}) => theme.media.desktop} {
    display: flex;
      width: auto;
      padding: ${({isSecondLeftSide}) => (isSecondLeftSide ? "0 20px" : "0 10px")};
    flex-direction: ${({isOneLine}) => (isOneLine ? "row" : "column")};
  }
`;

export const StyledPakiet = styled.div`
  position: relative;
  width: 60%;
  min-width: 250px;
  margin-left: 0;
  ${({theme}) => theme.media.tablet} {
    margin-left: 10%;
    width: 80%;
  }
  ${({theme}) => theme.media.desktop} {
    margin-left: 0;
    width: 100%;
    min-width: 200px;
  }
`;

export const Img = styled.img`
  width: ${({isLeft}) => (isLeft ? "200px" : "130px")};
  height: ${({isLeft}) => (isLeft ? "280px" : "180px")};
  margin-right: 1em;
  margin-bottom: 10px;
`;

export const Img2 = styled.img`
  width: ${({isLeft}) => (isLeft ? "170px" : "120px")};
  height: ${({isLeft}) => (isLeft ? "240px" : "160px")};
  margin-bottom: 10px;
  transition: transform 0.3s ease-in;
  ${({theme}) => theme.media.tablet} {
    width: ${({isLeft}) => (isLeft ? "170px" : "120px")};
    height: ${({isLeft}) => (isLeft ? "240px" : "160px")};
    margin-right: -2.5em;
    margin-bottom: 10px;
    transition: transform 0.3s ease-in;
    &:hover {
      text-decoration: none;
      transform: scale(1.1);
      z-index: 999;
    }
  }
  ${({theme}) => theme.media.desktop} {
    width: ${({isLeft}) => (isLeft ? "170px" : "120px")};
    height: ${({isLeft}) => (isLeft ? "240px" : "160px")};
    margin-right: -2.5em;
    margin-bottom: 15px;
    transition: transform 0.3s ease-in;
    &:hover {
      text-decoration: none;
      transform: scale(1.1);
      z-index: 999;
    }
    ${({isThird}) =>
  isThird &&
  css`
              margin-right: 0;
            `}
  }
`;

export const H2 = styled.h2`
  color: ${({isBlue}) =>
  isBlue ? ({theme}) => theme.blue : ({theme}) => theme.blackfont};
  font-size: ${({theme}) => theme.font.size.xm};
  margin-top: ${({isTop}) => (isTop ? "20px" : "5px")};
  margin-bottom: 10px;
  font-weight: 600;
  ${({theme}) => theme.media.desktop} {
    color: ${({isBlue}) =>
  isBlue ? ({theme}) => theme.blue : ({theme}) => theme.blackfont};
    font-size: ${({theme}) => theme.font.size.m};
    margin-top: ${({isTop}) => (isTop ? "25px" : "10px")};
    margin-bottom: 10px;
    font-weight: 600;
  }
`;

export const H3 = styled.h3`
  width: 90%;
  color: ${({isBlue}) =>
  isBlue ? ({theme}) => theme.blue : ({theme}) => theme.blackfont};
  font-size: ${({theme}) => theme.font.size.xs};
  margin-top: ${({isTop}) => (isTop ? "25px" : "10px")};
  margin-bottom: 10px;
  font-weight: bold;
  line-height: 1.4;
`;

export const DIV = styled.a`
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
`;
export const Description = styled.div`
  width: 85%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 5px;
  ${({theme}) => theme.media.desktop} {
    width: auto;
    max-width: 460px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    margin-right: 4%;
  }
`;
export const Images = styled.div`
  width: 100%;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  ${({theme}) => theme.media.tablet} {
    width: 90%;
    min-width: 450px;
    margin-right: 0%;
    position: relative;
    left: 0;
    top: 0;
    z-index: 99;
    display: flex;
    flex-direction: row;
  }
  ${({theme}) => theme.media.desktop} {
    width: 67%;
    min-width: 440px;
    margin-right: 0;
    position: relative;
    left: 0;
    top: 0;
    z-index: 99;
    display: flex;
    flex-direction: row;
    ${({isThird}) =>
  isThird &&
  css`
         padding-right: 20px; 
        display: grid;
        grid-template-columns: 1fr 1fr;
        place-items: center;
          `}
  }
`;
export const A = styled.a`
  z-index: ${({isFirstt}) => (isFirstt ? "99" : "77")};
  &:hover {
    text-decoration: none;
    transform: scale(1.05);
    z-index: 99999;
  }
  &:nth-child(2) {
    margin-top: ${({isSecond}) => (isSecond ? "10px" : "10px")};
   
  }
  ${({theme}) => theme.media.desktop} {
    &:nth-child(2) {
      margin-top: ${({isSecond}) => (isSecond ? "45px" : "10px")};
      z-index: 88;
      &:hover {
        text-decoration: none;
        transform: scale(1.05);
        z-index: 99999;
      }
    }
  }
  
  &:nth-child(3) {
    padding-top: 10px;
    margin: 0 auto;
    z-index: ${({isFirstt}) => (isFirstt ? "77" : "99")};
    &:hover {
      text-decoration: none;
      transform: scale(1.05);
      z-index: 99999;
    }
    ${({theme}) => theme.media.desktop} {
      margin:0;
      margin-top: 20px;
      
    }
    img {
      width: 130px;
      height: 180px;
    }
  }
  ${({isThird}) =>
  isThird &&
  css`
            width: auto;
            &:nth-child(2) {
              margin-top: 10px;
            }
            ${({theme}) => theme.media.desktop} {
              &:nth-child(2) {
                margin-top: 0;
              }
            }
          `}
`;

export const StyledLeftSide=styled.div`
    width: 100%;
    text-align: left;    
   
`
export const StyledLeftTitle=styled.h4`
    width: 92%;
    text-align: left;
    padding: 10px 0;
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    font-size: ${({theme}) => theme.font.size.s};
    color: ${({theme}) => theme.blue};
    font-weight: bold;
`
export const StyledImages=styled.div`
  
`
