import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import Pakiet from 'src/components/Pakiet/Pakiet';
import {Price, StyledDiv, Button, StyledA, StyledP, OnSell } from 'src/components/Textbooks/JointStyles'
import {
  StyledSP14,
  StyledInner,
  StyleUp,
  StyledLeft,
  StyledRight,
  StyledRightInner,
  StyledLeftWrapper,
  StyledPakiet,
  Img,
  Img2,
  H2,
  H3,
  DIV,
  Description,
  Images,
  A,
  StyledImages,
  StyledLeftTitle,
  StyledLeftSide
}
  from "src/components/Textbooks/ES14.styles"
import formatMoney from "../../utils/formatMoney";


const SP14 = ({ category, one, isFirst, isSecond, isThird, noBorder, isSecondLeftSide }) => {
  const data = useStaticQuery(graphql`
    query {
      allDatoCmsSp14(sort: { fields: sort }) {
        nodes {
          id
          sort
          description
          title
          leftsite {
            ... on DatoCmsImgbook {
              id
              imgbook {
                url
                filename
                customData
                alt
              }
            }
            ... on DatoCmsImagesbook {
              id
              imagesbook {
                alt
                filename
                customData
                title
                url
               
              }
            }
            ... on DatoCmsTextcontent {
              id
              textcontent
            }
            ... on DatoCmsHeadtitlebook {
              id
              headtitlebook
            }
            ... on DatoCmsPrice {
              id
              price
            }
            ... on DatoCmsButtonbook {
              id
              buttonbook
            }
            ... on DatoCmsOnsellfrom {
          id
          onsell
        }
          }
          rightbook {
            ... on DatoCmsImgbookr {
              id
              imgbookr {
                filename
                customData
                title
                url
                alt
                 
              }
            }
            ... on DatoCmsTextcontentr {
              id
              textcontentr
            }
            ... on DatoCmsHeadtitlebookr {
              id
              headtitler
            }
            ... on DatoCmsButtonbookr {
              id
              buttonbookr
            }
            ... on DatoCmsPricebookr {
              id
              pricebookr
            }
            ... on DatoCmsOnsellfrom {
          id
          onsell
        }
          }
           leftsitesecond {
        ... on DatoCmsImgbook {
          id
          imgbook {
            url
            filename
            customData
            alt
          }
        }
        ... on DatoCmsTextcontent {
          id
          textcontent
        }
        ... on DatoCmsHeadtitlebook {
          id
          headtitlebook
        }
         ... on DatoCmsPrice {
          id
          price
        }
        ... on DatoCmsButtonbook {
          id
          buttonbook
        }
        ... on DatoCmsOnsellfrom {
          id
          onsell
        }        
      }
      secondleftside
      titlesecondside
        }
      }
    }
  `);
  return (
    <StyledSP14>
      {data.allDatoCmsSp14.nodes
        .filter((Sp14) => Sp14.sort === category)
        .map((Sp14) => (
          <StyledDiv id={Sp14.sort} key={Sp14.id}>
            <StyleUp>
              <H2 isBlue>{Sp14.title}</H2>
              {Sp14.description && <H3>{Sp14.description}</H3>}
            </StyleUp>

            <StyledInner noBorder={noBorder} isOneLine={one}>
              <StyledLeftWrapper isSecondLeftSide={isSecondLeftSide}>
              <StyledLeft isThird={isThird} isOneLine={one}>
                <StyledImages isThird={isThird}>
                  {Sp14.leftsite.map((item) => {
                    const itemKey = Object.keys(item)[1];

                    switch (itemKey) {
                      case "imgbook":
                        return (
                          <DIV key={item.id} target="_blank" href={item[itemKey].alt}>
                            <Img
                              isLeft
                              isThird={isThird}
                              key={item.id}
                              src={item[itemKey].customData.ownUrl}
                              alt={item[itemKey].title}
                            />

                          </DIV>
                        );
                      case "imagesbook":
                        // eslint-disable-next-line no-case-declarations
                        const foto = item.imagesbook.map((i) => (
                          <A
                            isSecond={isSecond}
                            isFirstt={isFirst}
                            isThird={isThird}
                            target="_blank"
                            key={i.title}
                            href={i.alt}
                          >
                            <Img2
                              isThird={isThird}
                              isLeft
                              src={i.customData.ownUrl}
                              alt={i.title}
                              key={i.id}
                            />
                          </A>
                        ));
                        return (
                          <Images  isThird={isThird} isFirstt={isFirst} key={item.id+1}>
                            {foto}
                          </Images>

                        );

                      default:
                        return null;
                    }
                  })}
                </StyledImages>

                <Description>
                  {Sp14.leftsite.map((item) => {
                    const itemKey = Object.keys(item)[1];

                    switch (itemKey) {
                      case "hheadtitlebook":
                        return (
                          <H3
                            key={item.id}
                            dangerouslySetInnerHTML={{
                              __html: item.headtitlebook,
                            }}
                          />
                        );
                      case "textcontent":
                        return (
                          <StyledP
                            key={item.id}
                            dangerouslySetInnerHTML={{
                              __html: item.textcontent,
                            }}
                          />
                        );
                      case "price":
                        return (
                          <Price
                            key={item.id}
                          >{formatMoney(item.price.slice(0,-2))}</Price>
                        );

                      case "buttonbook":

                        return (item.buttonbook ? (
                            <Button key={item.id}>
                              <StyledA target="_blank" rel="noopener" href={item.buttonbook}>
                                Przejdź do sklepu
                              </StyledA>
                            </Button>) : null
                        );

                      case 'onsell':
                        return item.onsell ?  <OnSell >{item.onsell}</OnSell> : null;

                      default:
                        return null;
                    }
                  })}
                </Description>
              </StyledLeft>
                {Sp14.secondleftside&&<StyledLeftSide><StyledLeftTitle>{Sp14.titlesecondside}</StyledLeftTitle> </StyledLeftSide>}
                {/*{Sp14.secondleftside&&<h3 style={{textAlign:"left", width:"100%"}}>Coś tam</h3>}*/}
                {Sp14.secondleftside&&<StyledLeft isThird={isThird} isOneLine={one}>
                <StyledImages isThird={isThird}>
                  {Sp14.leftsitesecond.map((item) => {
                    const itemKey = Object.keys(item)[1];

                    switch (itemKey) {
                      case "imgbook":
                        return (
                          <DIV key={item.id} target="_blank" href={item[itemKey].alt}>
                            <Img
                              isLeft
                              isThird={isThird}
                              key={item.id}
                              src={item[itemKey].customData.ownUrl}
                              alt={item[itemKey].title}
                            />

                          </DIV>
                        );
                      case "imagesbook":
                        // eslint-disable-next-line no-case-declarations
                        const foto = item.imagesbook.map((i) => (
                          <A
                            isSecond={isSecond}
                            isFirstt={isFirst}
                            isThird={isThird}
                            target="_blank"
                            key={i.title}
                            href={i.alt}
                          >
                            <Img2
                              isThird={isThird}
                              isLeft
                              src={i.customData.ownUrl}
                              alt={i.title}
                              key={i.id}
                            />
                          </A>
                        ));
                        return (
                          <Images  isThird={isThird} isFirstt={isFirst} key={item.id+1}>
                            {foto}
                          </Images>

                        );

                      default:
                        return null;
                    }
                  })}
                </StyledImages>

                <Description>
                  {Sp14.leftsitesecond.map((item) => {
                    const itemKey = Object.keys(item)[1];

                    switch (itemKey) {
                      case "hheadtitlebook":
                        return (
                          <H3
                            key={item.id}
                            dangerouslySetInnerHTML={{
                              __html: item.headtitlebook,
                            }}
                          />
                        );
                      case "textcontent":
                        return (
                          <StyledP
                            key={item.id}
                            dangerouslySetInnerHTML={{
                              __html: item.textcontent,
                            }}
                          />
                        );
                      case "price":
                        return (
                          <Price
                            key={item.id}
                          >{formatMoney(item.price.slice(0,-2))}</Price>
                        );

                      case "buttonbook":

                        return (item.buttonbook ? (
                            <Button key={item.id}>
                              <StyledA target="_blank" rel="noopener" href={item.buttonbook}>
                                Przejdź do sklepu
                              </StyledA>
                            </Button>) : null
                        );

                      case 'onsell':
                        return item.onsell ?  <OnSell >{item.onsell}</OnSell> : null;

                      default:
                        return null;
                    }
                  })}
                </Description>
              </StyledLeft>}
              </StyledLeftWrapper>
              <StyledRight isOneLine={one}>
                <StyledRightInner isOneLine={one}>
                  <div>
                    {Sp14.rightbook.map((item) => {
                      const itemKey = Object.keys(item)[1];

                      switch (itemKey) {
                        case "imgbookr":
                          return (
                            <DIV key={item.id} target="_blank" rel="noopener" href={item[itemKey].alt}>
                              <Img
                                key={item.id}
                                src={item[itemKey].customData.ownUrl}
                                alt={item[itemKey].filename}
                              />
                            </DIV>
                          );
                        default:
                          return null;
                      }
                    })}
                  </div>

                  <div>
                    {Sp14.rightbook.map((item) => {
                      const itemKey = Object.keys(item)[1];

                      switch (itemKey) {
                        case "textcontentr":
                          return (
                            <StyledP
                              key={item.id}
                              dangerouslySetInnerHTML={{
                                __html: item.textcontentr,
                              }}
                            />
                          );
                        case "buttonbookr":
                          return (item.buttonbookr ?
                              <Button key={item.id}>
                                <StyledA target="_blank" rel="noopener" href={item.buttonbookr}>
                                  Przejdź do sklepu
                                </StyledA>
                              </Button> : null
                          ) ;
                        case "pricebookr":
                          return (
                            <Price
                              key={item.id}
                            >{formatMoney(item.pricebookr.slice(0,-2))}</Price>
                          );
                        case 'onsell':
                          return item.onsell ?  <OnSell >{item.onsell}</OnSell> : null;
                        default:
                          return null;
                      }
                    })}
                  </div>
                </StyledRightInner>
                <StyledPakiet>
                  <Pakiet />
                </StyledPakiet>
              </StyledRight>
            </StyledInner>
          </StyledDiv>
        ))}
    </StyledSP14>
  );
};

SP14.propTypes = {
  category: PropTypes.string.isRequired,
  one: PropTypes.bool,
  isSecondLeftSide: PropTypes.bool,
};
SP14.defaultProps = {
  one: false,
}

export default SP14;
